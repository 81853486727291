

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

p {
  text-align: left;
}

.container {
  margin-left: 5%;
  margin-right: 5%;
}

h3 {
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Link,
#basic-nav-dropdown {
  font-weight: 500 !important;
  /* font-size: 1em !important; */
  color: #000 !important;
  text-transform: uppercase;
   
}

.navbar-toggler {
  border: var(--bs-border-width) solid #f8f9fa !important;
}

.carousel-link {
  background-color: #f728c7;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 30px;
  color: white;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
  left: 0%;
  margin-top: 20px;
}

.nav-link1,#basic-nav-dropdown1 {
  color: rgb(1, 0, 0) !important;
  padding: none !important;
  font-size: 1em !important;   
  font-weight: 700 !important;                                                              

  font-family: 'Bebas Neue', sans-serif;
                                                                                            
}

.seperator {
  margin-top: 5 !important;
  margin-right: 10 !important;
  margin-left: 10 !important;
}

.nav-link {
  padding: none !important;    
}

.footer-links{
  text-align: right;
  color: #fff;
  margin-top: 10px;
}

.footer-links span{

  margin: 0 10px;
}

.footer-links a {
  text-decoration: none;
  font-size: .9em;
  color: #fff;
}

.social-media{
  margin: 10px 0;
}

.social-media a{
  /* border: 1px solid #fff; */
  padding: 5px;
  margin: 5px;
  color: #fff;
  border-radius: 5px;
  width: 40px !important;
  height: 40px !important;
}

.social-media a svg{
  width: 30px;
  height: 30px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bebas Neue', sans-serif; 
}



.icon-box{
  border:'1px solid silver' !important; 
  padding:'10px';
  width:'100px'; 
  height:'60px'; 
  margin:'0 auto'
}


form {
  width: 100%;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
/* button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
} */
/* 
button:hover {
  filter: contrast(115%);
} */

/* button:disabled {
  opacity: 0.5;
  cursor: default;
} */

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}


.react-calendar {
  width: 100% !important;
  max-width: 100%;
  background: white;
  border: 0px solid transparent !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}


.custom-font{
  font-family: 'Circular','-apple-system','BlinkMacSystemFont','Roboto','Helvetica Neue',sans-serif !important;
}

.active {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #c10944;
  font-size: 16px;
  background-color: #61dafb;
}


.thank-you-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  color: #fff;
  font-family: 'Arial', sans-serif;
}

.thank-you-card {
  text-align: center;
  background: #fff;
  color: #333;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
}

.thank-you-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: rgb(2, 185, 211);
}

.thank-you-message {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #555;
}

.thank-you-details {
  font-size: 0.9rem;
  margin-bottom: 2rem;
  color: #333;
}

.thank-you-button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  background: rgb(2, 185, 211);
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background 0.3s ease;
}

.thank-you-button:hover {
  background: #2575fc;
}
